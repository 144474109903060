.container {
    position: relative;
    width: 100%;
    background: #202020;
    
}

.innerCont {
    width: 100%;
    padding-top: clamp(71px, 22.2222vw, 182.2px);
    padding-bottom: clamp(113.7px, 35.5555vw, 291.5px);
}

.pointText {
    color: var(--color-primary);
}

/* title */

.title01 {
    width: clamp(277.3px, 86.6666vw, 710.6px);
    font-size: clamp(24.8px, 7.7777vw, 63.7px);
    margin: 0 auto;
    color: var(--color-common-wh);
    font-weight: var(--font-regular);
    letter-spacing: -0.01em;
    line-height: 1.286em;
    white-space: nowrap;
}

.title01 .cont03_H1_02 {
    /* height: 1.286em; */
    padding: 0.12em 0;
}

.title02 {
    width: clamp(277.3px, 86.6666vw, 710.6px);
    font-size: clamp(23.1px, 7.22222vw, 59.2px);
    font-weight: var(--font-regular);
    letter-spacing: -0.01em;
    line-height: 1.385em;
    color: var(--color-common-wh);
    white-space: pre-wrap;
    margin-left: auto;
    margin-top: clamp(133.3px, 41.6666vw, 341.6px);
}

/* content */

.content01,
.content03 {
    margin-top: clamp(78.2px, 24.4444vw, 200.4px);
}

.content02,
.content04 {
    margin-top: clamp(64.8px, 20.27777vw, 166.3px);
}

.content03,
.content04 {
    width: clamp(277.3px, 86.6666vw, 710.6px);
    margin-left: auto;
}

.content01 .imgWrap,
.content02 .imgWrap {
    margin-right: auto;
}

.content01 .textWrap,
.content02 .textWrap {
    width: 86.6666vw;
    margin: 0 auto;
    margin-top: clamp(26.6px, 8.3333vw, 68.3px);
}

.content03 .textWrap,
.content04 .textWrap {
    width: 100%;
    margin-top: clamp(26.6px, 8.3333vw, 68.3px);
}

.imgWrap {
    position: relative;
    width: clamp(277.3px, 86.6666vw, 710.6px);
    height: clamp(174.2px, 54.4444vw, 446.4px);
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}

.imgTrans {
    width: 100%;
    height: 100%;
}

.imgWrap img {
    width: 100%;
    height: 100%;
    /* transition: .4s ease; */
    object-fit: cover;
}

.textWrap {
    color: var(--color-common-wh);
    white-space: pre-wrap;
}

.textWrap span {
    margin-right: 0.150em;
}

.textWrap h3 {
    font-size: clamp(21.3px, 6.6666vw, 54.6px);
    font-weight: var(--font-light);
    line-height: 1em;
    letter-spacing: -0.02em;
}

.textWrap p {
    font-size: clamp(13.3px, 4.16666vw, 34.1px);
    font-weight: var(--font-light);
    line-height: 1.400em;
    letter-spacing: -0.01em;
    margin-top: 0.933em;
}

.exceptionText {
    width: clamp(260px, 81vw, 646.8px);
}


/* scroll event */

.hover {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(222, 110, 22, 0.86);
    opacity: 0;
    z-index: 1;
    /* transition: .4s ease; */
}

.hover p {
    color: var(--color-common-wh);
    font-size: clamp(13.3px, 4.16666vw, 34.1px);
    line-height: 1.667em;
    font-weight: var(--font-light);
} 



/* animation */

.cont03_H1_01, 
.cont03_H1_02,
.cont03_H1_03 {
    opacity: 0;
}

.ctnElmH1_01 .cont03_H1_01,
.ctnElmH1_02 .cont03_H1_01 {
    animation: cont03_H1 1000ms ease-in-out forwards;
    animation-delay: 200ms;
}

.ctnElmH1_01 .cont03_H1_02,
.ctnElmH1_02 .cont03_H1_02 {
    animation: cont03_H1 1000ms ease-in-out forwards;
    animation-delay: 400ms;
}

.ctnElmH1_01 .cont03_H1_03,
.ctnElmH1_02 .cont03_H1_03 {
    animation: cont03_H1 1000ms ease-in-out forwards;
    animation-delay: 600ms;
}

@keyframes cont03_H1 {
    0% {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
}


/* cont 01 */

.content01,
.content02,
.content03,
.content04 {
    opacity: 0;
}

.textWrap h3,
.textWrap p {
    opacity: 0;
}


.content01.ctnElm01,
.content02.ctnElm02,
.content03.ctnElm03,
.content04.ctnElm04 {
    transition: 1000ms ease-in-out;
    transition-delay: 650ms;
    opacity: 1;
    transition-property: opacity;
}

.content01.ctnElm01 .textWrap h3,
.content02.ctnElm02 .textWrap h3,
.content03.ctnElm03 .textWrap h3,
.content04.ctnElm04 .textWrap h3 {
    transition: 800ms ease-in-out;
    transition-delay: 900ms;
    opacity: 1;
    transition-property: opacity;
}

.content01.ctnElm01 .textWrap p,
.content02.ctnElm02 .textWrap p,
.content03.ctnElm03 .textWrap p,
.content04.ctnElm04 .textWrap p {
    transition: 800ms ease-in-out;
    transition-delay: 1000ms;
    opacity: 1;
    transition-property: opacity;
}

/* all img inner */

.hover {
    transition: 1000ms ease-in-out;
    transition-property: opacity;
}

.imgWrap img {
    transform: scale(1);
    transition: 1000ms ease-in-out;
    transition-property: transform;
}

.ctnElm01_img .hover,
.ctnElm02_img .hover,
.ctnElm03_img .hover,
.ctnElm04_img .hover {
    opacity: 1;
    text-align: center
}

.content01 .imgWrap.ctnElm01_img img,
.content02 .imgWrap.ctnElm02_img img,
.content03 .imgWrap.ctnElm03_img img,
.content04 .imgWrap.ctnElm04_img img {
    transform: scale(1.2);
}

