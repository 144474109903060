.container {
    height: 100vh;
    background: var(--color-common-wh);
    display: flex;
    align-items: center;
}

.textWrap {
    margin-top: clamp(16.8px, 1.35416vw, 34.6px);
    margin-left: clamp(111px, 8.95833vw, 229.3px);
}

.title {
    font-size: clamp(45.2px, 3.64583vw, 93.3px);
    font-weight: var(--font-regular);
    color: var(--color-01-depth);
    line-height: 1.200em;
    margin-bottom: 0.571em;
}

.subText {
    width: clamp(258.3px, 20.8333vw, 533.3px);
    font-size: clamp(11.6px, 0.9375vw, 24px);
    font-weight: var(--font-regular);
    color: var(--color-01-depth);
    line-height: 1.625em;
    letter-spacing: -0.01em;
    white-space: pre-wrap;
}

.subText_kr {
    display: block;
}

.subText_en {
    display: inline;
}