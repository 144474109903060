.container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    transition: .8s ease-in-out;
    transition-property: height;
}

/* backgrond Video */

.videoWrap {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.videoWrap::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desc {
    position: absolute;
    font-size: clamp(13px, 4.0625vw, 33.3125px);
    font-weight: var(--font-light);
    color: var(--color-common-wh);
    bottom: 1.385em;
    left: 6.6667vw;
    z-index: 100;
    opacity: 0;
    transition: 1450ms ease-in-out;
    transition-property: opacity;
}

.active .desc {
    opacity: 0.65;
}

/* content wrap */

.contentWrap {
    width: 86.6666vw;
    display: flex;
    flex-direction: column;
    z-index: 0;
    margin-bottom: clamp(120px, 37.5vw, 307.5px);
}

/* title */

.titleBox {
    width: 100%;
    z-index: 10;
    margin-top: auto;
}

.titleBox h2 {
    width: 100%;
    color: var(--color-common-wh);
    font-size: clamp(32px, 10vw, 82px);
    font-weight: var(--font-regular);
    line-height: 1.139em;
    opacity: 0;
}


/* button */

.button {
    display: flex;
    flex-direction: column;
    width: clamp(111px, 34.7222vw, 284.7px);
    margin-top: clamp(40px, 12.5vw, 102.5px);
}

.button p {
    color: var(--color-common-wh);
    font-size: clamp(12.44px, 3.8888vw, 31.88px);
    font-weight: var(--font-light);
    opacity: 0;
}

.buttonIco {
    width: 100%;
    position: relative;
    margin-top: 5px;
    opacity: 0;
    left: -60%;

}

.bIcoWrap01 {
    width: 100%;
    position: absolute;
}

.bIco01 {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--color-common-wh);
}

.bIco02 {
    display: block;
    position: absolute;
    right: -2px;
    width: 12px;
    top: -4px;
    height: 1px;
    background: var(--color-common-wh);
    transform: rotate(45deg);
}

/* animation */

.active .cnt1_ani_t01 {
    animation: cnt1-t-kf01 1200ms ease-in-out forwards;
    animation-delay: 1450ms;
}
.active .cnt1_ani_t02 {
    animation: cnt1-t-kf01 1200ms ease-in-out forwards;
    animation-delay: 1600ms;
}
.active .cnt1_ani_t03 {
    animation: cnt1-t-kf01 1200ms ease-in-out forwards;
    animation-delay: 1750ms;
}

.active .cnt1_ani_t04 {
    animation: cnt1-t-kf02 900ms ease-in-out forwards;
    animation-delay: 2250ms;
}

.active .buttonIco {
    animation: cnt1-ico-kf01 1400ms ease-in-out forwards;
    animation-delay: 2100ms;
}

@keyframes cnt1-t-kf01 {
    0% {
      transform: translate3d(0, -25%, 0);
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
}

@keyframes cnt1-t-kf02 {
    0% {
      transform: translate3d(0, -25%, 0);
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }

  @keyframes cnt1-ico-kf01 {
    0% {
        opacity: 0;
        left: -60%;
    }
    50% {
        opacity: 0;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }