.container {
    /* position: absolute; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 400ms ease-in-out;
    transition-property: opacity;
    z-index: 1000;
}

.closeArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.frame {
    display: flex;
    width: clamp(607px, 48.9583vw, 1253.3px);
    height: clamp(335.8px, 27.08vw, 693.3px);
    transition: 400ms ease-in-out;
    transition-property: opacity;
    z-index: 100;
}

.frame div {
    width: 100%;
    height: 100%;
}

.frame iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (max-width: 820px) {
    .frame {
        width: 100vw;
        height: calc((100vw / 16) * 9);
    }

}

@media (max-width: 915px) and (orientation: landscape) {
    /* Landscape 모드일 때 적용할 CSS */
    .frame {
        width: 100vw;
        height: 100%;
    }

}