.container  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
}

.guideWrap {
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.53) 5.65%, rgba(0, 0, 0, 0) 53.97%);
    display: flex;
    justify-content: center;
    transition: 1000ms ease-in-out;
    transition-property: opacity;
}

.inDisplay {
    opacity: 0;
    transition: 1000ms ease-in-out;
    pointer-events: none;
}

.gcw{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: clamp(103px, 8.33333vw, 213px);
    transition: 800ms ease-in-out;
    transition-delay: 1800ms;
    transition-property: opacity;
}

.mouse {
    position: relative;
    width: 22px;
    height: 49px;
    margin-top: 15px;
}

.mouseB {
    position: relative;
    display: block;
    width: 100%;
    height: 67.3469%;
    border: solid 2px var(--color-common-wh);
    border-radius: 30px;
    box-sizing: border-box;
}

.mouseW {
    position: absolute;
    left: calc(50% - 1px);
    top: 7px;
    width: 2px;
    height: 5px;
    background: var(--color-common-wh);
    border-radius: 2px;
    animation: mouseWheel 1200ms ease-in-out infinite;
}

.down {
    display: block;
    position: absolute;
    left: calc(50%);
    bottom: -12%;
    animation: mousedown 1200ms ease-in-out infinite;
}

.d01 {
    display: block;
    position: absolute;
    bottom: 7px;
    left: calc(50% - 1.5px);
    width: 6px;
    height: 2px;
    background: var(--color-common-wh);
    transform: rotate(-45deg);
    animation: mousedownOpacity01 1200ms ease-in-out infinite;
}

.d02 {
    display: block;
    position: absolute;
    bottom: 7px;
    right: calc(50% - 1.5px);
    width: 6px;
    height: 2px;
    background: var(--color-common-wh);
    transform: rotate(45deg);
    animation: mousedownOpacity01 1200ms ease-in-out infinite;
}

.d03 {
    display: block;
    position: absolute;
    bottom: -1px;
    left: calc(50% - 1.5px);
    width: 6px;
    height: 2px;
    background: var(--color-common-wh);
    transform: rotate(-45deg);
    animation: mousedownOpacity02 1200ms ease-in-out infinite;
}

.d04 {
    display: block;
    position: absolute;
    bottom: -1px;
    right: calc(50% - 1.5px);
    width: 6px;
    height: 2px;
    background: var(--color-common-wh);
    transform: rotate(45deg);
    animation: mousedownOpacity02 1200ms ease-in-out infinite;
}

.guideWrap p {
    font-size: 16px;
    letter-spacing: 0.031em;
    line-height: 1.149em;
    color: var(--color-common-wh);
}

@keyframes mouseWheel {
    0% {
        top: 14px;
    }
    40% {
        top: 7px;
    }
    100% {
        top: 14px;
    }
}

@keyframes mousedown {
    0% {
        bottom: -12%;
    }

    50% {
        bottom: -2%;
    }

    100% {
        bottom: -12%;
    }
}

@keyframes mousedownOpacity01 {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes mousedownOpacity02 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
