:root {
    /* color */
    --color-primary : #F58025;
    --color-primary-bg: #E88C45;
    --color-secondary-Bg: #C64A4A;
    --color-title: #FCF4EB;
    --color-description: #E5E3DE;
    --color-overlay-wh: #FFFFFF;
    --color-01-depth: #1B1B1B;
    --color-bg: #2E2B26;
    --color-common-bk: #000000;
    --color-common-wh: #FFFFFF;

    /* font */
    --font-ultralight: 200;
    --font-light: 300;
    --font-regular: 400;
    --font-bold: 700;

    /* 조정 전
    --font-ultralight: 200;
    --font-light: 300;
    --font-regular: 400;
    --font-bold: 700; */
}
