.container {
    position: relative;
    height: 100vh;
}

.assetsWrap{
    width: 100%;
    height: 100%;
}

.desc {
    position: absolute;
    font-size: clamp(10px, 0.625vw, 16px);
    font-weight: var(--font-light);
    color: var(--color-common-wh);
    bottom: 4.000em;
    left: 3.667em;
    z-index: 100;
}