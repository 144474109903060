.container {
    display: flex;
    height: 100vh;
    margin: 0;
    cursor: grab;
}

.wheelBody {
    display: flex;
    flex-direction: row;
    height: 100vh;
    z-index: 1;
}
