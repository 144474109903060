.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 10;
    pointer-events: none;
    /* transition: 400ms ease-out; */
    opacity: 0;
    transition: 400ms ease-out;
    transition-delay: 1400ms;
    transition-property: opacity;
}

/* header */

.header {
    width: 100%;
    height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: auto;
}

.contentLeft {
    display: flex;
    margin-left: clamp(32.3px, 2.60416vw, 66.7px);
    align-items: center;
}

.contentRight {
    display: flex;
    margin-right: clamp(34.9px, 2.8125vw, 72px);
    align-items: center;
}
/* logo */

.logo {
    cursor: pointer;
}

/* header menu */

.menu {
    width: 28px;
    height: 28px;
    padding: 5.5px 4px 5px 3px;
    box-sizing: border-box;
    margin-right: 50px;
    cursor: pointer;
}

.menu .m01 {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--color-common-wh);
    margin-bottom: 7px;
    transition: .4s ease-in-out;
    transition-property: background-color width;
}

.menu .m02 {
    display: block;
    width: 71.4285%;
    height: 1px;
    background: var(--color-common-wh);
    margin-bottom: 7px;
    transition: .4s ease-in-out;
    transition-property: background-color width;
}

.menu .m03 {
    display: block;
    width: 38.0952%;
    height: 1px;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color width;
}

/* header language */

.language {
    font-size: 18px;
    display: flex;
    margin-right: 1.889em;
}

.language .kr {
    color: var(--color-common-wh);
    height: 1.222em;
    line-height: 1.222em;
    font-size: 1em;
    font-weight: var(--font-light);
    margin-top: 0.111em;
    margin-right: 1.111em;
    transition: .4s ease-in-out;
    transition-property: color, opacity;
    cursor: pointer;
}

.language .en {
    color: var(--color-common-wh);
    height: 1.222em;
    line-height: 1.222em;
    font-size: 1em;
    font-weight: var(--font-light);
    margin-top: 0.111em;
    transition: .4s ease-in-out;
    transition-property: color, opacity;
    cursor: pointer;
}

/* header sound */

.sound {
    display: flex;
    align-items: flex-end;
    width: 28px;
    height: 28px;
    padding: 4px 2px;
    margin-bottom: 4px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    justify-content: space-between;

}

.sound .s01 {
    display: block;
    width: 1px;
    height: 100%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
    overflow: hidden;
}

.sound .s02 {
    display: block;
    width: 1px;
    height: 45%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}

.sound .s03 {
    display: block;
    width: 1px;
    height: 65%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}

.sound .s04 {
    display: block;
    width: 1px;
    height: 95%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}

.sound .s05 {
    display: block;
    width: 1px;
    height: 80%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}


/* footer */

.footer {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    color: var(--color-common-wh);
    font-size: 10px;
    font-weight: var(--font-light);
    letter-spacing: 0.050em;
    opacity: .3;
    transition: .4s ease-in-out;
    transition-property: color;
}


/* wheelani */

.lightTheme .language .kr,
.lightTheme .language .en {
    color: var(--color-common-wh);
}

.darkTheme .language .kr,
.darkTheme .language .en {
    color: var(--color-common-bk);
}

.lightTheme .sound .s01,
.lightTheme .sound .s02,
.lightTheme .sound .s03,
.lightTheme .sound .s04,
.lightTheme .sound .s05 {
    background: var(--color-common-wh);
}

.darkTheme .sound .s01,
.darkTheme .sound .s02,
.darkTheme .sound .s03,
.darkTheme .sound .s04,
.darkTheme .sound .s05 {
    background: var(--color-common-bk);
}


.lightTheme .menu .m01,
.lightTheme .menu .m02,
.lightTheme .menu .m03 {
    background: var(--color-common-wh);
}

.darkTheme .menu .m01,
.darkTheme .menu .m02,
.darkTheme .menu .m03 {
    background: var(--color-common-bk);
}

.lightTheme .footer p {
    color: var(--color-common-wh);
}

.darkTheme .footer p {
    color: var(--color-common-bk);
}

/* menu */

@media (hover: hover) {
    .menu:hover .m01 {
        width: 100%;
        transition-duration: .3s;
    }

    .menu:hover .m02 {
        width: 100%;
        transition-duration: .3s;
    }

    .menu:hover .m03 {
        width: 100%;
        transition-duration: .3s;
    }
}



