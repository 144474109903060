

.container {
    display: flex;
    flex-direction: row;;
    height: 100vh;
}

.content02 {
    width: 100%;
    position: relative;
    height: 100%;
    background: var(--color-common-wh);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* layout */
.contentWrap {
    margin-bottom: clamp(10px, 0.78125vw, 20px);
    margin-left: clamp(93px, 7.5vw, 192px);
}

/* title */
.titleBox {
    position: relative;
    z-index: 10;
}

.titleBox h2 {
    font-size: clamp(45px, 3.64583vw, 93px);
    font-weight: var(--font-regular);
    letter-spacing: -0.01em;
    line-height: 1.200em;
    color: var(--color-01-depth);
}


/* sub text */

.subText {
    margin-top: clamp(30px, 2.3958vw, 61px);
}

.subText p {
    font-size: clamp(11.5px, 0.9375vw, 24px);
    font-weight: var(--font-regular);
    color: var(--color-01-depth);
    line-height: 1.556em;
    white-space: pre-wrap;

}

/* assets */

.assetsWrap {
    position: absolute;
    width: clamp(646px, 52.08333vw, 1333.5px);
    height: clamp(422.5px, 34.0625vw, 872px);
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: 1000;
}

.afterVideo {
    width: 100%;
    height: 100%;
}

.beforeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 40;
}

.handlerLine {
    position: absolute;
    width: 2px;
    top: 0;
    height: 100%;
    background: rgba(245, 128, 37, 0.4);
    z-index: 50;
    pointer-events: none;
}
.handlerIco {
    position: absolute;
    top: 50%;
    left: -20px;
    width: 43px;
    height: 43px;
    border-radius: 22px;
    background: rgba(245, 128, 37, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.39);
}
