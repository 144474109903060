.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #F8F6F4;
    text-align: center;
    color: var(--color-01-depth);
}

.contentWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.descWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 69px;
}

.descWrap img {
    width: 174px;
    margin-bottom: 17px;
}

.descWrap h3 {
    font-size: 20px;
    font-weight: var(--font-pre-medium);
    margin-bottom: 14px;
}

.descWrap p {
    font-size: 16px;
    font-weight: var(--font-pre-regular);
    line-height: 1.563em;
}

.iconWrap {
    display: flex;
    width: 344px;
    justify-content: space-between;
}

.iconWrap img {
    margin-bottom: 8px;
}

.iconWrap a {
    font-size: 16px;
    font-weight: var(--font-pre-regular);
    color: var(--color-01-depth);
    transition: 300ms ease-in-out;
    transition-property: color;
}

.icon03 img {
    transform: scale(1.1);
}


@media (hover: hover) {

    .iconWrap div:hover a{
        color: var(--color-primary);
    }

}