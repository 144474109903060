.container {
    position: relative;
    width: 100vw;
    background: #FFFFFF;
    padding-top: clamp(71.1px, 22.2222vw, 182.2px);
    box-sizing: border-box;
}

.textWrap {
    width: 86.6666vw;
    margin:  0 auto;
}

.title {
    font-size: clamp(32px, 10vw, 82px);
    line-height: 1.139em;
    font-weight: var(--font-regular);
    color: var(--color-01-depth);
    margin-bottom: 0.722em;
    opacity: 0;
}

.subText {
    font-size: clamp(15.1px, 4.7222vw, 38.7px);
    line-height: 1.412em;
    letter-spacing: -0.01em;
    font-weight: var(--font-regular);
    color: var(--color-01-depth);
    white-space: pre-wrap;
    opacity: 0;
}

/* ani */

.textWrap.active01 .title {
    opacity: 1;
    transition: 1000ms ease-in-out;
    transition-delay: 200ms;
    transition-property: opacity;
}

.textWrap.active01 .subText {
    opacity: 1;
    transition: 1000ms ease-in-out;
    transition-delay: 400ms;
    transition-property: opacity;
}

/* swiper */

:global .cardSwiper_m {
    width: 100vw !important;
    overflow-x: scroll;
    margin-top: clamp(63.1px, 19.7222vw, 161.7px);
    padding-bottom: clamp(77.3px, 24.1666vw, 198.1px);
}

:global .cardSwiper_m::-webkit-scrollbar {
    width: 0;
    height: 0;
}



:global .cardElm_m {
    position: relative;
    box-sizing: border-box !important;
    margin-right: clamp(10.66px, 3.33333vw, 27.33px);
  }

/* swiper-pagination */

:global .swiper-pagination {
    position: absolute;
    display: flex;
    width: 86.6666vw;
    left: calc(50% - 43.3333vw);
    height: 2px;
    background: chocolate;
    bottom: clamp(40.8px, 12.7777vw, 104.7px);
}

:global .swiper-pagination-bullet {
    width: 100%;
    background: #E0E0E0;
    transition: .4s ease-in-out;
}

:global .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 100%;
    background: #616161;
    transition: .4s ease-in-out;
}

  @media screen and (max-width: 820px) {

      :global .swiper-wrapper {
          display: flex;
      }
  }