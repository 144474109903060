.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(369px, 29.791666vw, 763px);
    height: calc(var(--vh, 1vh) * 100);
    z-index: 100;
    background: linear-gradient(160.82deg, #F8F6F4 63.25%, rgba(255, 255, 255, 0.9) 115.66%);
    transition: 400ms ease-in-out;
    transition-property: transform;
    z-index: 1000;
}

.isPointer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    
}

.close {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 38px;
    left: 50px;
    cursor: pointer;
}

.c_01, .c_02 {
    position: absolute;
    top: calc(50% - 2px);
    transform: rotate(45deg);
    width: 100%;
    height: 1px;
    background: var(--color-01-depth);
    transition: 0.4s ease-in-out;
    transition-property: transform;
}

.c_02 {
    transform: rotate(-45deg);
}

/* menu in */

.close.active .c_01 {
    transform: rotate(-225deg);
}

.close.active .c_02 {
    transform: rotate(45deg);
}

.menuWarp {
    width: 59.44055%;
    margin-bottom: clamp(103px, 8.33333vw, 213px);
}

.menuWarp ul li:not(.m_04) {
    margin-bottom: 1.458em;
}

.menuWarp ul li {
    font-size: clamp(31px, 2.5vw, 64px);
    font-weight: var(--font-regular);
    letter-spacing: -0.02em;
    line-height: 1.208em;
    color: var(--color-01-depth);
    cursor: pointer;
    transition: 400ms ease-in-out;
    transition-property: color;
}

@media (hover: hover) {

    .close:hover .c_01 {
        transform: rotate(45deg);
    }

    .close:hover .c_02 {
        transform: rotate(-45deg);
    }


    .menuWarp ul li:hover {
        color: var(--color-primary);
    }

}
