.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transition: 400ms ease-out;
    transition-delay: 1400ms;
    transition-property: opacity;
}

/* header */

.header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: auto;
}

.contentLeft {
    display: flex;
    margin-left: 24px;
    align-items: center;
}

.contentRight {
    display: flex;
    margin-right: 24px;
    align-items: center;
}
/* logo */

.logo {
    margin-top: 8px;
    cursor: pointer;
}

/* header menu */

.menu {
    width: 22px;
    height: 22px;
    padding: 5.5px 3.5px 4.5px 2px;
    box-sizing: border-box;
    margin-right: 18px;
    cursor: pointer;
}

.menu .m01 {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--color-common-wh);
    margin-bottom: 5px;
    transition: .4s ease-in-out;
    transition-property: background-color width;
}

.menu .m02 {
    display: block;
    width: 71.4285%;
    height: 1px;
    background: var(--color-common-wh);
    margin-bottom: 5px;
    transition: .4s ease-in-out;
    transition-property: background-color width;
}

.menu .m03 {
    display: block;
    width: 38.0952%;
    height: 1px;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color width;
}


/* header sound */

.sound {
    display: flex;
    align-items: flex-end;
    width: 22px;
    height: 22px;
    padding: 4px 2px;
    margin-bottom: 4px;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    justify-content: space-between;

}

.sound .s01 {
    display: block;
    width: 1px;
    height: 100%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
    overflow: hidden;
}

.sound .s02 {
    display: block;
    width: 1px;
    height: 45%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}

.sound .s03 {
    display: block;
    width: 1px;
    height: 65%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}

.sound .s04 {
    display: block;
    width: 1px;
    height: 95%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}

.sound .s05 {
    display: block;
    width: 1px;
    height: 80%;
    background: var(--color-common-wh);
    transition: .4s ease-in-out;
    transition-property: background-color;
}


/* wheelani */

.lightTheme .language .kr,
.lightTheme .language .en {
    color: var(--color-common-wh);
}

.darkTheme .language .kr,
.darkTheme .language .en {
    color: var(--color-common-bk);
}

.lightTheme .sound .s01,
.lightTheme .sound .s02,
.lightTheme .sound .s03,
.lightTheme .sound .s04,
.lightTheme .sound .s05 {
    background: var(--color-common-wh);
}

.darkTheme .sound .s01,
.darkTheme .sound .s02,
.darkTheme .sound .s03,
.darkTheme .sound .s04,
.darkTheme .sound .s05 {
    background: var(--color-common-bk);
}


.lightTheme .menu .m01,
.lightTheme .menu .m02,
.lightTheme .menu .m03 {
    background: var(--color-common-wh);
}

.darkTheme .menu .m01,
.darkTheme .menu .m02,
.darkTheme .menu .m03 {
    background: var(--color-common-bk);
}

.lightTheme .footer p {
    color: var(--color-common-wh);
}

.darkTheme .footer p {
    color: var(--color-common-bk);
}

/* menu */

@media (hover: hover) {
    .menu:hover .m01 {
        width: 100%;
        transition-duration: .3s;
    }

    .menu:hover .m02 {
        width: 100%;
        transition-duration: .3s;
    }

    .menu:hover .m03 {
        width: 100%;
        transition-duration: .3s;
    }
}



