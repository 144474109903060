

.container {
    height: 100vh;
    background: #202020;
    display: flex;
    align-items: center;
}

.pointText {
    color: var(--color-primary);
}

.title01 {
    font-size: clamp(34px, 2.7604vw, 70.5px);
    color: var(--color-common-wh);
    font-weight: var(--font-regular);
    letter-spacing: -0.01em;
    line-height: 1.283em;
    margin-left: clamp(149px, 12.03125vw, 308px);
    white-space: nowrap;
}

.title02 {
    font-size: clamp(31.6px, 2.55208vw, 65.3px);
    font-weight: var(--font-regular);
    letter-spacing: -0.02em;
    line-height: 1.367em;
    color: var(--color-common-wh);
    margin-left: clamp(204.7px, 16.5104vw, 422.6px);
    white-space: nowrap;
}


/* contnet */

.content01 {
    margin-left: clamp(103.3px, 8.33333vw, 213.3px);
}

.content01 .textWrap {
    margin-top: clamp(23.9px, 1.92708vw, 49.3px);
}

.content02 {
    margin-left: clamp(100.7px, 8.125vw, 208px);
}

.content02 .textWrap {
    margin-bottom: clamp(25.2px, 2.03125vw, 52px);
}

.content03 {
    margin-left: clamp(112.3px, 9.0625vw, 232px);
}

.content03 .textWrap {
    margin-top: clamp(23.9px, 1.92708vw, 49.3px);
}

.content04 {
    margin-left: clamp(85.25px, 6.875vw, 176px);
}

.content04 .textWrap {
    margin-bottom: clamp(23.9px, 1.92708vw, 49.3px);
}

/* content all*/

.imgWrap {
    position: relative;
    width: clamp(184px, 14.84375vw, 380px);
    height: clamp(240px, 19.375vw, 496px);
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}

.imgTrans {
    width: 100%;
    height: 100%;
}

.imgWrap img {
    height: 100%;
    transition: .4s ease;
    object-fit: cover;
}

.textWrap {
    color: var(--color-common-wh);
    white-space: pre-wrap;
}

.textWrap h3 {
    font-size: clamp(25.8px, 2.08333vw, 53.3px);
    font-weight: var(--font-light);
    line-height: 1.200em;
}

.textWrap span {
    color: var(--color-primary);
}

.textWrap p {
    width: clamp(245.4px, 19.79166vw, 506.6px);
    font-size: clamp(11.6px, 0.9375vw, 24px);
    font-weight: var(--font-light);
    line-height: 1.444em;
    margin-top: 0.667em;
}

.hover {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(222, 110, 22, 0.7);
    opacity: 0;
    z-index: 1;
    transition: .4s ease;
}

.hover p {
    text-align: center;
    color: var(--color-common-wh);
    font-size: clamp(10px, 0.72916vw, 18.6px);
    font-weight: var(--font-regular);
    padding: 0.34em 0;
    line-height: 1.1em;
    white-space: pre-wrap;
}


@media (hover: hover) {

    .imgWrap:hover img {
        transform: translate3d(0, 0, 0) scale(110%);
    }
    
    .imgWrap:hover .hover{
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(110%);
    }

}