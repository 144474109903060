.container {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contentWrap {
    margin-bottom: clamp(11px, 0.885416vw, 22.5px);
}

/* desc */

.desc {
    position: absolute;
    font-size: clamp(10px, 0.625vw, 16px);
    font-weight: var(--font-light);
    color: var(--color-common-wh);
    bottom: 4.000em;
    left: 4.167em;
}


/* title */

.titleBox {
    margin-left: clamp(75px, 6.041666vw, 155px);
    z-index: 10;
}

.titleBox h2 {
    color: var(--color-common-wh);
    font-size: clamp(53px, 4.27083vw, 109px);
    font-weight: var(--font-regular);
    line-height: 1.134em;
    opacity: 0;
}

.titleBox h2:last-child {
    line-height: 1.195em;
}




/* button */

.button {
    display: flex;
    flex-direction: column;
    width: clamp(378.4px, 30.5208vw, 781.3px);
    margin-top: clamp(35px , 2.8125vw, 72px);
    margin-left: clamp(75px, 6.041666vw, 155px);
    padding: 12px 0;
    cursor: pointer;
    overflow: hidden;
}

.button p {
    color: var(--color-common-wh);
    font-size: clamp(10px, 0.67708vw, 17px);
    font-weight: var(--font-light);
    opacity: 0;
}

.buttonIco {
    width: 43%;
    position: relative;
    margin-top: 10px;
    left: -60%;
    transition: 600ms ease-in-out;
    opacity: 0;
}

.bIcoWrap01 {
    width: 100%;
    position: absolute;
    left: 0;
}

.bIco01 {
    display: block;
    width: 100%;
    height: 1px;
    background: var(--color-common-wh);
}

.bIco02 {
    display: block;
    position: absolute;
    right: -2px;
    width: 12px;
    top: -4px;
    height: 1px;
    background: var(--color-common-wh);
    transform: rotate(45deg);
}


@media (hover: hover) {
    .button:hover .buttonIco {
        width: 100%;
    }
}

/* animation */

.active .cnt1_ani_t01 {
    transform: translate3d(0, -45%, 0);
    animation: cnt1-t-kf01 1200ms ease-in-out forwards;
    animation-delay: 1500ms;
}
.active .cnt1_ani_t02 {
    transform: translate3d(0, -45%, 0);
    animation: cnt1-t-kf01 1200ms ease-in-out forwards;
    animation-delay: 1600ms;
}
.active .cnt1_ani_t03 {
    transform: translate3d(0, -45%, 0);
    animation: cnt1-t-kf01 1200ms ease-in-out forwards;
    animation-delay: 1700ms;
}
.active .cnt1_ani_t04 {
    transform: translate3d(0, -45%, 0);
    animation: cnt1-t-kf02 700ms ease-in-out forwards;
    animation-delay: 2100ms;
}

.active .buttonIco {
    animation: cnt1-ico-kf01 1400ms ease-in-out forwards;
    animation-delay: 1700ms;
}
 

@keyframes cnt1-t-kf01 {
    0% {
      transform: translate3d(0, -45%, 0);
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
}

@keyframes cnt1-t-kf02 {
    0% {
      transform: translate3d(0, -45%, 0);
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }

  @keyframes cnt1-ico-kf01 {
    0% {
        opacity: 0;
        left: -60%;
    }
    50% {
        opacity: 0;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }