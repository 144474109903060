.container {
    height: 100vh;
    background: #F8F6F4;
    display: flex;
    align-items: center;
}

/* web */

.webContainer {
    margin-top: clamp(37px, 2.96875vw, 76px);
    margin-left: clamp(298.3px, 24.0625vw, 616px);
}

.webImgWrap {
    width: clamp(193.75px, 15.625vw, 400px);
}

.webImgWrap img {
    width: 100%;
}

.webTitle h2 {
    color: var(--color-01-depth);
    font-size: clamp(23.25px, 1.875vw, 48px);
    letter-spacing: -0.01em;
    line-height: 1.250em;
    font-weight: var(--font-bold);
    white-space: pre-wrap;
    margin-top: 1.083em;
    margin-bottom: 0.722em;
}

.webDesc p,
.webDesc a {
    color: var(--color-01-depth);
    font-weight: var(--font-regular);
    font-size: clamp(10.3px, 0.83333vw, 21.3px);
    letter-spacing: -0.01em;
    line-height: 1.500em;
}

.webDesc a {
    color: var(--color-primary);
}


/* app */

.appContainer {
    margin-top: clamp(27px, 2.1875vw, 56px);
    margin-left: clamp(179px, 14.4270vw, 369.3px);
}

.appImgWrap {
    width: clamp(248px, 20vw, 512px);
}

.appImgWrap img {
    width: 100%;
}

.appTitle h2 {
    color: var(--color-01-depth);
    font-size: clamp(23.25px, 1.875vw, 48px);
    letter-spacing: -0.01em;
    line-height: 1.250em;
    font-weight: var(--font-bold);
    white-space: pre-wrap;
    margin-bottom: 0.722em;
}


.appDesc p {
    color: var(--color-01-depth);
    font-weight: var(--font-regular);
    font-size: clamp(10.3px, 0.83333vw, 21.3px);
    letter-spacing: -0.01em;
    line-height: 1.500em;
    margin-bottom: 1.875em;
}
.appBtnWrap {
    display: flex;
    margin-bottom: clamp(25px, 2.03125vw, 52px);
}

.appBtnWrap button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(90.4px, 7.29166vw, 186.6px);
    height: clamp(29.7px, 2.39583vw, 61.3px);
    border-radius: 31px;
    border: none;
    background: var(--color-primary-bg);
    opacity: 0.2;
}

.androidBtn {
    margin-right: clamp(8.4px, 0.67708vw, 17.3px);
}

.androidBtn img {
    width: clamp(15.6px, 1.25vw, 32px);
    margin-right: clamp(5.8px, 0.46875vw, 12px);
}

.iosBtn img {
    width: clamp(9px, 0.8854vw, 22.6px);
    margin-right: clamp(6.45px, 0.5208vw, 13.3px);
    margin-bottom: 3px;
}

.appBtnWrap button p {
    color: var(--color-common-wh);
    font-size: clamp(10.3px, 0.83333vw, 21.3px);
    font-weight: var(--font-bold);
    letter-spacing: -0.01em;
    line-height: 1.188em;
    height: 1.188em;
}

/* go main btn */

.goMainContainer {
    position: relative;
    width: clamp(378.4px, 30.5208vw, 781.3px);
    margin-left: auto;
    cursor: pointer;
    overflow: hidden;
    padding: 20px 0;
    z-index: 10;
}



.goMainText {
    opacity: 0;
    transform: translate3d(20%, 0, 0);
    text-align: end;
    margin: 0 5.154em 0.769em auto;
    color: #8a8a8a;
    font-size: clamp(10px, 0.67708vw, 17.3px);
    font-weight: var(--font-light);
    line-height: 1.231em;
    transition-delay: 400ms;
    transition-property: opacity;
}

.arrowIco01 {
    opacity: 0;
    transform: translate3d(43%, 0, 0);
    position: relative;
    transition: 600ms ease-in-out;
    right: -40%;
}

.arrowIco01.activeIco{
    transition: 1000ms ease-in-out;
    opacity: 1;
    right: 0;
}

.goMainText.activeTxt {
    transition: 800ms ease-in-out;
    transform: translate3d(0, 0, 0);
    opacity: 1;
}


.arrow01 {
    display: block;
    width: 100%;
    height: 1px;
    background: #8D8D8D;
}

.arrow02 {
    display: block;
    position: absolute;
    left: -2px;
    width: 12px;
    top: -4px;
    height: 1px;
    background: #8D8D8D;
    transform: rotate(-45deg);
}

.blurWrap {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    pointer-events: none;
    opacity: 0;
    transition: 500ms ease-in-out;
}



@media (hover: hover) {
    .goMainContainer:hover .arrowIco01 {
        transform: translate3d(0, 0, 0);
    }

    .goMainContainer:hover ~.blurWrap {
        opacity: 1;
    }

}


@keyframes cnt1-ico-kf01 {
    0% {
        opacity: 0;
        right: -60%;
    }
    50% {
        opacity: 0;
    }
    100% {
      opacity: 1;
      right: 0;
    }
  }