.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: #F8F6F4;
    text-align: center;
    color: var(--color-01-depth);
}

.contentWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.descWrap h3 {
    font-size: 20px;
    font-weight: var(--font-bold);
    margin-top: 0.850em;
    margin-bottom: 0.750em;
}

.descWrap p {
    font-size: 16px;
    font-weight: var(--font-regular);
    margin-bottom: 3.250em;
}

.homeBtn {
    width: 226px;
    height: 46px;
    border-radius: 23em;
    background: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeBtn a {
    width: 100%;
    height: 100%;
    line-height: 48px;
    font-size: 16px;
    font-weight: var(--font-bold);
    color: var(--color-common-wh);
    text-decoration: none;
}

@media (max-width: 425px) {

    .descWrap img {
        width: 140px;
    }

    .descWrap h3 {
        font-size: 16px;
    }

    .descWrap p {
        font-size: 11px;
    }

    .homeBtn {
        width: 180px;
        height: 37px;
        border-radius: 23em;
    }

    .homeBtn a {
        line-height: 39px;
        font-size: 12px;
    }

}