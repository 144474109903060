
/* Bold */

@font-face {
	font-family: 'NanumBarunGothic';
	font-weight: 700;
	src: url('../../assets/fonts/NanumBarunGothicBoldSubset.eot');
	src: url('../../assets/fonts/NanumBarunGothicBoldSubset.eot?#iefix') format('embedded-opentype'),
		 url('../../assets/fonts/NanumBarunGothicBoldSubset.woff2') format('woff2'),
		 url('../../assets/fonts/NanumBarunGothicBoldSubset.woff') format('woff'),
		 url('../../assets/fonts/NanumBarunGothicBoldSubset.ttf') format('truetype');
   }

/* Regular */

@font-face {
	font-family: 'NanumBarunGothic';
	font-weight: 400;
	src: url('../../assets/fonts/NanumBarunGothicSubset.eot');
	src: url('../../assets/fonts/NanumBarunGothicSubset.eot?#iefix') format('embedded-opentype'),
		 url('../../assets/fonts/NanumBarunGothicSubset.woff2') format('woff2'),
		 url('../../assets/fonts/NanumBarunGothicSubset.woff') format('woff'),
		 url('../../assets/fonts/NanumBarunGothicSubset.ttf') format('truetype');
   }

/* Light */

   @font-face {
	font-family: 'NanumBarunGothic';
	font-weight: 300;
	src: url('../../assets/fonts/NanumBarunGothicLightSubset.eot');
	src: url('../../assets/fonts/NanumBarunGothicLightSubset.eot?#iefix') format('embedded-opentype'),
		 url('../../assets/fonts/NanumBarunGothicLightSubset.woff2') format('woff2'),
		 url('../../assets/fonts/NanumBarunGothicLightSubset.woff') format('woff'),
		 url('../../assets/fonts/NanumBarunGothicLightSubset.ttf') format('truetype');
   }

/* Ultra Light */

   @font-face {
	font-family: 'NanumBarunGothic';
	font-weight: 200;
	src: url('../../assets/fonts/NanumBarunGothicUltraLightSubset.eot');
	src: url('../../assets/fonts/NanumBarunGothicUltraLightSubset.eot?#iefix') format('embedded-opentype'),
		 url('../../assets/fonts/NanumBarunGothicUltraLightSubset.woff2') format('woff2'),
		 url('../../assets/fonts/NanumBarunGothicUltraLightSubset.woff') format('woff'),
		 url('../../assets/fonts/NanumBarunGothicUltraLightSubset.ttf') format('truetype');
   }