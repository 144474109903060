:global .cardSwiper {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-right: clamp(115px, 9.27083vw, 237.3px);
    margin-left: auto;
    height: 100vh;
  }

  :global .cardElm {
    position: relative;
    box-sizing: border-box !important;
  }

.container {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
}

:global .items {
    position: relative;
    height: 100%;
    cursor: pointer;
}

:global .item {
    width: 100%;
    height: 100%;
    position: absolute;
}

:global .item0, :global .item2 {
    top: clamp(77.5px, 6.25vw, 160px);
}

:global .swi_innerText {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: clamp(10px, 0.78125vw, 20px);
    padding: 1em 0.875em;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s ease-in-out;
    transition-property: opacity;
}

:global .swi_innerText p {
    color: var(--color-common-wh);
    /* font-size: clamp(12px, 0.9375vw, 24px); */
    line-height: 1.188em;
    font-weight: var(--font-regular);
    text-align: center;
    white-space: pre-wrap;
}

@media (hover: hover) {
    :global .item:hover .swi_innerText {
        opacity: 1;
    }
    
    :global .item:hover .swi_logoWrap {
        opacity: 0;
    }
}


:global .swi_logoWrap {
    opacity: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease-in-out;
    transition-property: opacity;
}

:global .swi_logoWrap img {
    width: 100%;
}
