.container {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 820px) {
    .cnt_02_m {
        position: absolute;
        width: clamp(298.6px, 93.3333vw, 765.3px);
        height: clamp(210.6px, 65.8333vw, 539.8px);
        object-fit: cover;
    }
}