.container {
    /* position: relative; */
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F6F4;
}

.masking {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: clamp(384.9px, 31.04166vw, 794.6px);
    height: clamp(191.1px, 15.41666vw, 394.6px);
    overflow: hidden;
    animation: ani01 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

.active .masking {
    width: clamp(827.9px, 66.77083vw, 1709.3px);
    height: clamp(410.75px, 33.125vw, 848px);
    animation: ani05 1400ms cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

.active .logoWrap {
    opacity: 1;
    animation: ani06 1000ms ease-in-out forwards;
}

.videoWrap {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.logoWrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    margin-bottom: clamp(-25.8px, -2.08333vw, -53.3px);
    cursor: pointer;
}

.logoMasking {
    overflow: hidden;
    height: clamp(38.75px, 3.125vw, 80px);
    transform: translate3d(0, 0, 0);
    animation: ani03 700ms ease-in-out forwards;
    animation-delay: 1800ms;
}

.logo {
    margin-top: clamp(38.75px, 3.125vw, 80px);
    opacity: 0;
    animation: ani02 800ms ease-in-out forwards;
    animation-delay: 400ms;
}

.text {
    font-size: clamp(13px, 1.04166vw, 26.6px);
    font-weight: var(--font-light);
    color: var(--color-common-wh);
    letter-spacing: 0.031em;
    opacity: 0;
    animation: ani04 500ms ease-in-out forwards;
    animation-delay: 2000ms;
}



@keyframes ani01 {
    0% {
        width: clamp(384.9px, 31.04166vw, 794.6px);
        height: clamp(191.1px, 15.41666vw, 394.6px);
    }
    100% {
        width: clamp(827.9px, 66.77083vw, 1709.3px);
        height: clamp(410.75px, 33.125vw, 848px);
    }
}

@keyframes ani02 {
    0% {
        margin-top: clamp(38.75px, 3.125vw, 80px);
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes ani03 {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, -85%, 0);
    }
}

@keyframes ani04 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ani05 {
    0% {
        width: clamp(827.9px, 66.77083vw, 1709.3px);
        height: clamp(410.75px, 33.125vw, 848px);
    }
    100% {
        width: 100vw;
        height: 100vh;
    }
}

@keyframes ani06 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media (max-width: 820px) {
    .container {
        height: calc(var(--vh, 1vh) * 100);
    }

    .masking {
        width: 32.22222vw;
        height: calc(var(--vh, 1vh) * 32.1875);
    }

    .logoMasking {
        height: 7.63888vw;
    }

    @keyframes ani01 {
        0% {
            width: 32.22222vw;
            height: calc(var(--vh, 1vh) * 32.1875);
        }
        100% {
            width: 76.1111vw;
            height: calc(var(--vh, 1vh) * 76.25);
        }
    }

    @keyframes ani03 {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(0, -63.6363%, 0);
        }
    }

    @keyframes ani05 {
        0% {
            width: 76.1111vw;
            height: calc(var(--vh, 1vh) * 76.25);
        }
        100% {
            width: 100vw;
            height: calc(var(--vh, 1vh) * 100);
        }
    }

}