@media (max-width: 820px) {

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    :global .item_m {
        position: relative;
        width: clamp(170.66px, 53.3333vw, 437.33px);
        height: clamp(113.77px, 35.5555vw, 291.55px);
    }

    :global .item_inner_m {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :global .item_m_0,
    :global .item_m_1 {
        margin-bottom: clamp(10.66px, 3.33333vw, 27.33px);
    }

    :global .item_m_0,
    :global .item_m_2 {
        left: clamp(84.4px, 26.3888vw, 216.38px);
    }

    :global .swi_logoWrap_m {
        opacity: 1;
        width: 57.29%;
        transition: 400ms ease-in-out;
        transition-property: opacity;
    }

    :global .swi_logoWrap_m img {
        width: 100%;
    }


    /* inner Text */

    :global .swi_innerText_m {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        font-size: clamp(13.3px, 4.16666vw, 34.1px);
        padding: 1.067em;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 400ms ease-in-out;
        transition-property: opacity;
    }

    :global .swi_innerText_m p {
        color: var(--color-common-wh);
        font-size: 1em;
        line-height: 1.188em;
        font-weight: var(--font-light);
        text-align: center;
        /* text ellipsis */
        /* display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        height: 6em;
        line-height: 1.2em;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis; */
    }

    :global .item_inner_m.mobileSwiperItem .swi_innerText_m {
        opacity: 1;
    }

    :global .item_inner_m.mobileSwiperItem .swi_logoWrap_m {
        opacity: 0;
    }

}