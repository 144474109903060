.container {
    position: relative;
    width: 100%;
    height: 100vh;
}

.assetsWrap{
    width: 100%;
    height: 100%;
}

.video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.desc {
    position: absolute;
    font-size: clamp(13px, 4.0625vw, 33.3125px);
    font-weight: var(--font-light);
    color: var(--color-common-wh);
    bottom: 1.385em;
    left: 1.846em;
    z-index: 100;
    opacity: 0;
}

.active01 {
    opacity: 0.65;
    transition: 800ms ease-in-out;
    transition-property: opacity;
}