.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    background: linear-gradient(160.82deg, #F8F6F4 63.25%, rgba(255, 255, 255, 0.9) 115.66%);
    transition: 400ms ease-in-out;
    transition-property: transform;
    z-index: 99999;
}

.close {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 19px;
    left: 24px;
    cursor: pointer;
}

.c_01, .c_02 {
    position: absolute;
    top: calc(50% - 2px);
    transform: rotate(45deg);
    width: 100%;
    height: 1px;
    background: var(--color-01-depth);
    transition: 0.4s ease-in-out;
    transition-property: transform;
}

.c_02 {
    transform: rotate(-45deg);
}

.menuWarp {
    width: 73.3333%;
    margin-bottom: clamp(106.6px, 33.3333vw, 273.3333px);
}

.menuWarp ul li:not(.m_04) {
    margin-bottom: 1.458em;
}

.menuWarp ul li {
    font-size: clamp(24.8px, 7.7777vw, 63.7777px);
    font-weight: var(--font-regular);
    letter-spacing: -0.02em;
    line-height: 1.143em;
    color: var(--color-01-depth);
    cursor: pointer;
    transition: 0.4s ease-in-out;
    transition-property: color;
}

/* language */

.language {
    width: 73.3333%;
    position: absolute;
    display: flex;
    align-items: center;
    left: (50% - 36.66665%);
    bottom: clamp(64px, 20vw, 164px);
}

.language div {
    font-size: clamp(14.2px, 4.4444vw, 36.4px)
}

.language .kr {
    margin-right: 0.625em;
}

.language .en {
    margin-left: 0.625em;
}

.language span {
    display: block;
    width: 1px;
    height: 0.750em;
    background: var(--color-01-depth);
}

.close.active .c_01 {
    transform: rotate(-225deg);
    transition: 400ms ease-in-out;
    transition-delay: 400ms;
    transition-property: transform;
}

.close.active .c_02 {
    transform: rotate(45deg);
    transition: 400ms ease-in-out;
    transition-delay: 400ms;
    transition-property: transform;
}

