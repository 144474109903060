/* Loading */

  .progress circle {
    animation: progress 2400ms 1 ease-in-out forwards;
  }
  @keyframes progress {
      0%{ stroke-dashoffset: 1360; }
      100%{ stroke-dashoffset: 0; }
  }

/* sound */

.activeSound .s01 {
  animation: soundAni01 1s ease-in-out infinite;
}

.activeSound .s02 {
  animation: soundAni02 1s ease-in-out infinite;
}

.activeSound .s03 {
  animation: soundAni03 1s ease-in-out infinite;
}

.activeSound .s04 {
  animation: soundAni04 1s ease-in-out infinite;
}

.activeSound .s05 {
  animation: soundAni05 1s ease-in-out infinite;
}


  @keyframes soundAni01 {
    0% { height: 100%;
    }
    50% {
      height: 40%;
    }
    100% {
      height: 100%;
    }    
  }

  @keyframes soundAni02 {
    0% {
      height: 40%;
    }
    50% {
      height: 45%;
    }
    100% {
      height: 40%;
    }    
  }

  @keyframes soundAni03 {
    0% {
      height: 65%;
    }
    50% {
      height: 40%;
    }
    100% {
      height: 65%;
    }    
  }

  @keyframes soundAni04 {
    0% {
      height: 40%;
    }
    50% {
      height: 95%;
    }
    100% {
      height: 40%;
    }    
  }

  @keyframes soundAni05 {
    0% {
      height: 80%;
    }
    50% {
      height: 40%;
    }
    100% {
      height: 80%;
    }    
  }














  @media screen and (max-width: 820px) {
    /* sound */

    .activeSound .s01 {
      animation: soundAni01 1s ease-in-out infinite;
    }

    .activeSound .s02 {
      animation: soundAni02 1s ease-in-out infinite;
    }

    .activeSound .s03 {
      animation: soundAni03 1s ease-in-out infinite;
    }

    .activeSound .s04 {
      animation: soundAni04 1s ease-in-out infinite;
    }

    .activeSound .s05 {
      animation: soundAni05 1s ease-in-out infinite;
    }

    @keyframes soundAni01 {
      0% { height: 90%;
      }
      50% {
        height: 65%;
      }
      100% {
        height: 90%;
      }    
    }
  
    @keyframes soundAni02 {
      0% {
        height: 40%;
      }
      50% {
        height: 50%;
      }
      100% {
        height: 40%;
      }    
    }
  
    @keyframes soundAni03 {
      0% {
        height: 65%;
      }
      50% {
        height: 50%;
      }
      100% {
        height: 65%;
      }    
    }
  
    @keyframes soundAni04 {
      0% {
        height: 60%;
      }
      50% {
        height: 85%;
      }
      100% {
        height: 60%;
      }    
    }
  
    @keyframes soundAni05 {
      0% {
        height: 80%;
      }
      50% {
        height: 60%;
      }
      100% {
        height: 80%;
      } 
    }

}