.container {
    position: relative;
    width: 100%;
    background: #F8F6F4;
    box-sizing: border-box;
    padding-top: clamp(104.8px, 32.7777vw, 268.7px);
    padding-bottom: clamp(32px, 10vw, 82px);
    overflow: hidden;
}

.appContainer {
    margin-top: clamp(99.5px, 31.1111vw, 255.1px);
}

.appContainer .imgWrap {
    margin-left: auto;
}


/* img */

.imgWrap {
    width: clamp(277.3px, 86.6666vw, 710.6px);
    height: clamp(174.2px, 54.4444vw, 446.4px);
}

.imgWrap img {
    width: 100%;
}

/* text */

.webContainer .textWrap {
    width: 86.6666vw;
    margin: 0 auto;
    margin-top: clamp(26.6px, 8.3333vw, 68.3px);
}

.appContainer .textWrap {
    width: 86.6666vw;
    margin-left: auto;
    margin-top: clamp(26.6px, 8.3333vw, 68.3px);
}


.title {
    color: var(--color-01-depth);
    font-size: clamp(23.1px, 7.2222vw, 59.2px);
    font-weight: var(--font-bold);
    letter-spacing: -0.01em;
    line-height: 1.385em;
    white-space: pre-wrap;
}

.desc {
    color: var(--color-01-depth);
    font-size: clamp(13.3px, 4.16666vw, 34.1px);
    font-weight: var(--font-regular);
    margin-top: 0.867em;
    letter-spacing: -0.01em;
    line-height: 1.467em;
}

.desc a {
    color: var(--color-primary);
}

/* btn */

.btnWrap {
    display: flex;
    margin-top: clamp(26.6px, 8.3333vw, 68.3px);
}

.btnWrap button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: clamp(103.1px, 32.2222vw, 264.2px);
    height: clamp(39.1px, 12.2222vw, 100.2px);
    font-size: clamp(12.4px, 3.8888vw, 31.8px);
    border-radius: 50px;
    border: none;
    background: var(--color-primary-bg);
    opacity: 0.2;
}

.androidBtn {
    margin-right: 0.714em;
}

.androidBtn img {
    width: 1.429em;
    margin-right: 0.500em;
}

.iosBtn img {
    width: 1.143em;
    margin-right: 0.500em;
}

.btnWrap button p {
    color: var(--color-common-wh);
    font-size: 1em;
    font-weight: var(--font-bold);
    letter-spacing: -0.01em;
    line-height: 1.143em;
    height: 1.143em;
}

/* goMainTrigger */

.goMainTrigger {
    margin: 0 auto;
    margin-top: clamp(177.7px, 55.5555vw, 455.5px);
    width: 86.6666vw;
    height: clamp(159.1px, 49.7222vw, 407.7px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.goMainTrigger p {
    color: #8a8a8a;
    font-size: clamp(10.6px, 3.3333vw, 27.3px);
    font-weight: #8A8A8A;
    line-height: 1.167em;
    /* margin-bottom: 1.417em; */
}

.arrowIco01 {
    width: clamp(4.8px, 1.52777vw, 12.5px);
    height: clamp(131.5px, 41.1111vw, 337.1px);
    position: relative;
    margin-bottom: 17px;
}


.arrow01 {
    display: block;
    width: 1px;
    height: 100%;
    background: #8D8D8D;
}

.arrow02 {
    display: block;
    position: absolute;
    left: 2px;
    width: 1px;
    top: -4px;
    height: 12px;
    background: #8D8D8D;
    transform: rotate(-45deg);
}

/* ani */

.imgWrap img{
    opacity: 0;
    transform: scale(1.3);
}

.imgWrap img.active01_sc,
.imgWrap img.active02_sc {
    animation: cont06_sc 1200ms ease-in-out forwards;
    /* animation-delay: 200ms; */
}

@keyframes cont06_sc {
    0% {
      opacity: 0;
      transform: scale(1.3);
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1.0);
    }
}

.title,
.desc,
.btnWrap {
    opacity: 0;
}

.active01_t .title,
.active02_t .title {
    transition: 1000ms ease-in-out;
    transition-delay: 200ms;
    opacity: 1;
    transition-property: opacity;
}

.active01_t .desc,
.active02_t .desc {
    transition: 800ms ease-in-out;
    transition-delay: 400ms;
    opacity: 1;
    transition-property: opacity;
}

.active02_t .btnWrap {
    transition: 800ms ease-in-out;
    transition-delay: 600ms;
    opacity: 1;
    transition-property: opacity;
}

.goMainTrigger {
    opacity: 0;
    transform: translate3d(0, 80%, 0);
}

.goMainTrigger.active03_ico {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: 600ms ease-in-out;
    transition-delay: 200ms;
    transition-property: opacity, transform;
}

.goMainTrigger p {
    opacity: 0;
}

.goMainTrigger.active03_ico p {
    transition: 800ms ease-in-out;
    transition-delay: 600ms;
    opacity: 1;
    transition-property: opacity;
}