.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var( --color-common-wh);
    overflow: hidden;
}

.contentWrap {
    width: 86.6666vw;
    margin: 0 auto;
}

/* title */

.titleBox {
    position: relative;
    margin-top: clamp(62.2px, 19.44444vw, 159.4px);
}

.titleBox h2 {
    font-size: clamp(25.7px, 8.05555vw, 66px);
    font-weight: var(--font-regular);
    letter-spacing: -0.01em;
    line-height: 1.310em;
    color: var(--color-01-depth);
}


/* sub text */

.subText {
    margin-top: clamp(11.5px, 3.61111vw, 29.6px);
}

.subText p {
    font-size: clamp(14.2px, 4.4444vw, 36.4px);
    font-weight: var(--font-regular);
    color: var(--color-01-depth);
    line-height: 1.438em;
    white-space: pre-wrap;
}

/* assets */

.assetsWrap {
    position: relative;
    width: clamp(298.6px, 93.3333vw, 765.3px);
    height: clamp(210.6px, 65.8333vw, 539.8px);
    margin-top: clamp(75.5px, 23.61111vw, 193.6px);
    margin-left: auto;
    margin-bottom: clamp(20.4px, 6.3888vw, 52.3px);
    z-index: 10;
}

.afterWrap {
    position: absolute;
    width: auto;
    height: 100%;
    overflow: hidden;
    z-index: 40;
    top: 0;
    left: 0;
}

.beforeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    padding: 0;
    overflow: hidden;
}


.handlerLine {
    position: absolute;
    width: 2px;
    top: 0;
    height: 100%;
    background: rgba(245, 128, 37, 0.4);
    z-index: 100;
}
.handlerIco {
    position: absolute;
    top: calc(50% - 18px);
    left: -18px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.39);
}

.handlerIco img {
    width: 20px;
}

/* animation */

.ani01_h1,
.ani02_h1,
.subText,
.assetsWrap {
    opacity: 0;
}

.active01 .ani01_h1{
    opacity: 1;
    transition: 1000ms ease-in-out;
    transition-delay: 200ms;
    transition-property: opacity;
}

.active01 .ani02_h1{
    opacity: 1;
    transition: 1000ms ease-in-out;
    transition-delay: 400ms;
    transition-property: opacity;
}

.active01 .subText {
    opacity: 1;
    transition: 800ms ease-in-out;
    transition-delay: 800ms;
    transition-property: opacity;
}

.assetsWrap.active02  {
    opacity: 1;
    transition: 1500ms ease-in-out;
    transition-delay: 500ms;
    transition-property: opacity;
}

@media (max-width: 915px) and (orientation: landscape) {
    /* Landscape 모드일 때 적용할 CSS */
    .assetsWrap {
        position: relative;
        width: 93.3333vw;
        height: 65.8333vw;
        margin-top: 23.61111vw;
        margin-bottom: 6.3888vw;
    }

}